.footer {
  background-image: url("../images/footer-bg.jpg");
  background-size: cover;
  padding: 50px 0 10px;
}
.footer .img-fluid {
  width: 146px;
}
footer h6,
.footer-menu-item a,
.footer-menu-item-form input,
.footer-menu-item-form textarea,
.footer-menu-item-form button,
.footer-menu-item-para,
.Copyright-text p,
form input {
  font-family: "Raleway", sans-serif;
}
.common-links-footer-contact a {
  font-family: "Inter", sans-serif;
}
.common-links-social {
  display: flex;
  justify-content: flex-start;
  margin-top: 60px;
  margin-bottom: 40px;
}
.common-links-social li {
  display: block;
}
.common-links-social li i {
  font-family: "Fontawesome";
}
.common-links-social li a {
  width: 50px;
  height: 50px;
  background: #2f333b;
  margin: auto;
  text-align: center;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.common-links-social li a:hover {
  background-color: #000000;
  color: #ffffff;
}

.common-links-footer {
  padding-left: 0;
}
.common-links-footer li,
footer ul li {
  margin: 10px 0;
}
.common-links-footer a,
footer ul li a {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.common-links-footer a i {
  color: #cc00cc;
}
.common-links-footer a span,
footer ul li a span {
  display: inline-block;
  margin-left: 10px;
}
footer ul {
  padding-left: 0;
}
footer h6 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #ffffff;
  margin-top: 21px;
  margin-bottom: 30px;
}
footer input,
footer textarea {
  color: #ffffff;
  background: rgba(32, 32, 32, 0.45);
  border: 1px solid rgba(177, 177, 177, 0.4);
  width: 85%;
  margin: 0 0 12px;
  padding: 14px 18px;
}
footer textarea {
  margin-bottom: 8px;
}
footer p {
  color: rgba(203, 203, 203, 1);
  font-size: 1rem;
  margin-bottom: 7px;
  margin-top: -4px;
}
.Copyright-text {
  margin-top: 50px;
}
.Copyright-text p {
  margin-bottom: 0;
  text-align: center;
}
