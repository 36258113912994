@media only screen and (min-width: 1200px) {
  .about-us-section .red-text-heading-up {
    margin-top: 22px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1350px) {
  .testimonial-section-menda .flex-divss div.profile-image {
    max-width: 60%;
  }
  .rating-box-test {
    padding: 20px 30px 0;
  }
  .rating-box-test-main h4.red-text-heading-up {
    padding-bottom: 11px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .rating-box-test-main h2 {
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .rating-box-test .user-comment {
    padding-top: 20px;
  }
  .banner-section-home {
    min-height: auto;
  }
}
@media only screen and (min-width: 991px) {
  .nav-menu.navbar ul.main-ul-box {
    display: flex;
    align-items: center;
  }
  .card-box-services.left-portion .card-services-content {
    padding-left: 0;
    margin-left: -172px;
    padding-right: 20px;
    padding-top: 50px;
  }
  .card-box-services.right-portion .card-services-content {
    padding-left: 52px;
    margin-right: -172px;
    padding-right: 14px;
    padding-top: 50px;
  }
  .card-box-services.left-portion {
    padding-left: 250px;
  }
  .card-box-services.right-portion {
    padding-right: 250px;
  }
  .white-bg-box.left-image img {
    position: relative;
    left: -250px;
  }
  .white-bg-box.right-image img {
    position: relative;
    right: -248px;
  }
  div.testimonials-carousel .owl-nav button,
  div.testimonials-carousel .owl-nav.disabled button,
  div.recent-article-carousel.owl-carousel .owl-nav button.owl-prev,
  div.recent-article-carousel.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: calc(50% - 30px);
    background: #cc00cc !important;
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
  div.forMenu {
    min-height: calc(100vh - 526px);
  }
}
@media only screen and (max-width: 991px) {
  .contact-form-div {
    padding: 20px;
  }
  html {
    font-size: 12px;
  }
  .navbar-mobile {
    position: fixed !important;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(40, 58, 90, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .rating-box-test-main {
    background: transparent;
  }
  .rating-box-test {
    background: #2e2e2e;
  }
  .mobile-nav-toggle {
    display: block;
  }
  .our-services-section .subpara-text,
  .our-services-section-bottom .subpara-text {
    max-width: 100%;
  }
  .red-text-heading-up {
    margin-top: 39px;
    margin-bottom: 20px;
  }
  .white-bg-box img {
    margin-bottom: 30px;
  }
  .card-services-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .white-bg-box {
    display: block !important;
  }
  .para-tex-value {
    padding-top: 20px;
  }
  .know-more-btn {
    margin-top: 20px;
  }
  .mobile-hide {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  .testimonials-carousel .owl-nav button.owl-prev,
  .recent-article-carousel .owl-nav button.owl-prev {
    left: 10px;
  }
  .testimonials-carousel .owl-nav button.owl-next,
  .recent-article-carousel .owl-nav button.owl-next {
    right: 10px;
  }
  .testimonials-carousel .owl-nav button.owl-next i {
    transform: rotate(-90deg);
  }
  .testimonials-carousel .owl-nav button.owl-prev i {
    transform: rotate(90deg);
  }
  .testimonials-carousel .owl-nav button,
  .recent-article-carousel .owl-nav button {
    position: absolute;
    top: calc(50% - 30px);
    background: #ffffff !important;
    border-radius: 100%;
    width: 30px;
    height: 30px;
  }
  .recent-article-carousel .owl-nav button {
    background: #cc00cc !important;
  }
  .card-services-content .product-name-text {
    margin-bottom: 20px;
  }
  .d-flex.mobile-menu {
    display: none !important;
  }
  .our-services-section-bottom,
  .our-services-section,
  .about-us-section {
    padding-bottom: 50px;
  }
  .navbar-mobile .mobile-menu {
    display: block !important;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar-mobile .mobile-menu .serch-form {
    margin: 15px 17px;
    width: auto;
    max-width: 100%;
  }
  .navbar-mobile ul {
    width: 100%;
  }
  .navbar-mobile ul li {
    float: none;
  }
  .navbar-mobile ul li a {
    margin-left: 0;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgb(127 137 161 / 25%);
    width: auto;
  }
  .navbar-mobile .mobile-nav-toggle:before {
    content: "\f00d";
    color: #ffffff;
  }
  .navbar-mobile .dropdown.dropdown-active ul {
    display: block;
  }
  .testimonial-section-menda .flex-divss div.profile-image {
    max-width: 100%;
  }
  .more-btn {
    margin-top: 20px;
  }
  .testimonial-section-menda .flex-divss {
    display: block !important;
  }
  .rating-box-test {
    padding-bottom: 20px;
  }
  footer input,
  footer textarea {
    width: 100%;
  }
  #header.header-transparent {
    margin-top: 0;
    position: fixed;
    background: #ffffff;
    padding-top: 10px;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 10px;
  }
  .box-heading {
    margin-bottom: 20px;
    margin-top: 35px;
  }
  .banner-section-home .red-box {
    margin-left: 0;
    margin-top: 30px;
  }
  .banner-section-home {
    padding-bottom: 55px;
  }
  .img-fluid {
    max-width: 100% !important;
    height: auto !important;
  }
}
@media only screen and (min-width: 1400px) {
  .container-fluid-equal-space {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .container-fluid-equal-space {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .nav-menu a.common-buttons-medna-contact-us-header {
    padding: 10px 28px;
  }
  #header.header-transparent {
    padding-left: 15px;
    padding-right: 15px;
  }
  .serch-form {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 991px) {
  .nav-menu.navbar {
    margin-top: 15px;
  }
  header .serch-form {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  div.testimonials-carousel .owl-nav button.owl-prev,
  div.testimonials-carousel .owl-nav.disabled button.owl-prev,
  .recent-article-carousel button.owl-prev {
    left: -27px;
  }
  div.testimonials-carousel .owl-nav button.owl-next,
  div.testimonials-carousel .owl-nav.disabled button.owl-next,
  .recent-article-carousel button.owl-next {
    right: -33px;
  }
  .brand-logo.d-flex {
    display: block !important;
  }
  .brand-text-box {
    padding-left: 0;
  }
  .container-fluid-equal-space {
    padding-left: 50px;
    padding-right: 50px;
  }
  .contact-form-div {
    padding: 35px 50px;
  }
  .nav-menu > ul > li {
    margin: 0 10px;
  }
  #header .logo img {
    max-width: 156px;
  }
  .nav-menu a.common-buttons-medna-contact-us-header {
    padding: 7px 14px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .right-image .image-section {
    text-align: right;
  }
  .banner-section-home {
    min-height: auto;
  }
}
@media only screen and (max-width: 991px) {
  div.testimonials-carousel .owl-nav button.owl-prev,
  div.testimonials-carousel .owl-nav.disabled button.owl-prev,
  .recent-article-carousel button.owl-prev {
    left: 4px;
  }
  div.testimonials-carousel .owl-nav button.owl-next,
  div.testimonials-carousel .owl-nav.disabled button.owl-next {
    right: 4px;
  }
  div.testimonials-carousel .owl-nav button i,
  div.testimonials-carousel .owl-nav.disabled button i {
    color: #cc00cc;
  }
}
@media only screen and (max-width: 767px) {
  .card-boxes-contact {
    flex: 0 0 100%;
    width: 100%;
  }
  .contact-detail .d-flex {
    display: block !important;
  }
  .contact-detail {
    padding-top: 50px;
  }
  .common-links-footer-contact li {
    margin: 0;
  }
  .common-links-footer-contact li a {
    height: 45px;
  }
  .common-links-social {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  footer h6 {
    margin-bottom: 10px;
  }
  .Copyright-text {
    margin-top: 21px !important;
  }
}
@media only screen and (max-width: 576px) {
  .our-services-section img {
    max-width: 100% !important;
  }
  .side-box {
    padding-left: 15px;
  }
  .brand-logo.d-flex {
    display: block !important;
  }
  .brand-logo.d-flex .brand-text-box {
    padding-left: 0;
  }
}
